<template>
  <div class="tab">
    <ul :class="['tab-list']">
      <div class="back" @click="$router.replace('/')">回到首页</div>
      <li :class="[active == index && 'active']" v-for="(item, index) in tabs" :key="index" @click="changeTab(index)">
        {{ item.label }}
      </li>
      <img :class="['logo', 'position-' + active]" src="../imgs/logo.png" alt="" />
    </ul>
  </div>
</template>

<script>
export default {
  props: ["active"],
  data() {
    return {
      tabs: [
        { label: "教师团队" },
        { label: "教学环境" },
        { label: "课程介绍" },
        { label: "校企合作" },
        { label: "大事件" },
        { label: "联系我们" },
      ],
    };
  },
  methods: {
    changeTab(index) {
      this.$emit("update:active", index);
    },
  },
};
</script>

<style lang="scss" scoped>
$fz: remPc(16);
$color: #57bd16;
.tab {
  width: remPc(180);
  height: remPc(470);
  &-list {
    padding-right: remPc(26);
    transform: rotate(-16deg) translateX(remPc(-104));
    color: #666;
    height: 100%;
    cursor: pointer;
    position: relative;
    text-align: right;
    &::after {
      content: "";
      height: 100%;
      width: 3px;
      background: $color;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
    }
    .back {
      font-size: remPc(19);
      line-height: remPc(90);
      font-weight: bold;
      transform: translateY(20px);
      &:hover {
        color: $color;
      }
    }
    li {
      line-height: remPc(52);
      font-size: $fz;
      transform: translateY(0px);
      &:hover {
        color: $color;
        font-weight: bold;
      }
      &.active {
        color: #fff;
        font-weight: bold;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: remPc(20);
          height: remPc(20);
          background: $color;
          border-radius: 50%;
          position: absolute;
          right: remPc(-26);
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:nth-of-type(2) {
        padding-left: remPc(10);
        line-height: remPc(40);
        transform: rotate(5deg) translateY(0px);
      }
      &:nth-of-type(3) {
        line-height: remPc(60);
        margin-top: remPc(20);
        transform: rotate(-5deg);
      }
      &:nth-of-type(4) {
        padding-left: remPc(10);
        transform: rotate(5deg);
      }
      &:nth-of-type(5) {
        margin: remPc(10) 0;
      }
    }
    .logo {
      position: absolute;
      left: 0;
      transform: translate(185%, remPc(34));
      top: 0;
      width: remPc(145);
      transition: transform 0.5s;
      &.position {
        &-1 {
          transform: translate(185%, remPc(92));
        }
        &-2 {
          transform: translate(185%, remPc(135));
        }
        &-3 {
          transform: translate(185%, remPc(210));
        }
        &-4 {
          transform: translate(185%, remPc(260));
        }
        &-5 {
          transform: translate(185%, remPc(325));
        }
      }
    }
    &.animate {
      &::after {
        height: 100%;
        transition: all 1.5s;
      }
    }
  }
}
</style>
