<template>
  <div :class="['tab-four', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-5.png" alt="" />
      <img class="background-2" src="../imgs/leaf-6.png" alt="" />
    </section>
    <section class="intro">
      <p>在职业教育校企融合浪潮下与全国高校进行深度合作。</p>
      <p>培养优秀的技术型才，搭建一个更具竞争力与前景的舞台。</p>
    </section>
    <section class="lists-wrap">
      <div class="lists">
        <section class="swiper-wrap">
          <swiper ref="pageSwiper" :options="swiperOptions">
            <swiper-slide class="slide" v-for="(item, index) in groupLists" :key="index">
              <ul>
                <li class="lists-item" v-for="(_item, _index) in item" :key="_index" @click="openPopup(_item, index)">
                  <span class="index">{{ _index | indexF(activeIndex) }}</span>
                  <img class="success" src="../imgs/successful.png" alt="" />
                  <img class="pic" :src="_item.files[0].url" alt="" />
                  <p class="desc">{{ _item.title }}</p>
                </li>
              </ul>
            </swiper-slide>
          </swiper>
        </section>
      </div>
      <div class="paging">
        <img class="arrow" @click="slidePrev('page')" src="../imgs/l-1.png" alt="" />
        <span class="number">第{{ activeIndex }}页</span>
        <img class="arrow" @click="slideNext('page')" src="../imgs/r-1.png" alt="" />
      </div>
    </section>
    <transition name="bounce">
      <Popup :visible.sync="visible" v-if="visible" :popupInfo="popupInfo"> </Popup>
    </transition>
  </div>
</template>

<script>
import Popup from "./PopupTab4";
export default {
  components: { Popup },
  props: ["lists"],
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      swiperOptions: {
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex + 1;
          },
        },
      },
      visible: false,
      animate: false,
      popupInfo: { files: [], title: "", desc: "" },
    };
  },
  computed: {
    groupLists() {
      return this.group(this.lists, 3);
    },
    pageSwiper() {
      return this.$refs.pageSwiper.$swiper;
    },
  },
  filters: {
    indexF(value, activeIndex) {
      const temp = (activeIndex - 1) * 6 + value + 1;
      return temp >= 10 ? temp : "0" + temp;
    },
  },
  methods: {
    group(array, subGroupLength) {
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    openPopup(info) {
      this.visible = true;
      this.popupInfo = Object.assign({}, info);
    },
    slidePrev(type) {
      this.pageSwiper.slidePrev();
    },
    slideNext(type) {
      this.pageSwiper.slideNext();
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-four {
  height: 100vh;
  overflow: hidden;
  position: relative;
  .background {
    &-1 {
      height: 38vh;
      position: absolute;
      left: remPc(-90);
      top: remPc(-100);
      z-index: 1;
      transform: rotate(-60deg);
      transform-origin: -100% 100%;
    }
    &-2 {
      height: 28vh;
      position: absolute;
      left: remPc(20);
      top: remPc(-45);
      z-index: 2;
      transform: rotate(-60deg);
      transform-origin: -50% 100%;
    }
  }
  .intro {
    position: absolute;
    left: remPc(180);
    top: 32vh;
    transform: rotate(-90deg);
    transform-origin: -50% center;
    p {
      color: #fff;
      font-size: remPc(20);
      letter-spacing: 2px;
      line-height: remPc(40);
    }
  }
  .lists-wrap {
    margin-top: 32vh;
    .lists {
      .swiper-wrap {
        ul {
          padding: 0 remPc(208);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .lists-item {
            padding: remPc(30) 0;
            position: relative;
            &:nth-of-type(1) {
              transform: translateX(500%);
            }
            &:nth-of-type(2) {
              transform: translateX(300%);
            }
            &:nth-of-type(3) {
              transform: translateX(200%);
            }
            .index {
              font-size: remPc(100);
              position: absolute;
              z-index: -1;
              top: 0px;
              left: 50%;
              line-height: 10vh;
              color: #8b53ec6f;
              font-weight: bold;
              transform: translateX(-50%);
            }
            .success {
              position: absolute;
              width: 50%;
              left: 50%;
              transform: translateX(-50%);
              top: remPc(85);
            }
            .pic {
              height: 32vh;
              cursor: pointer;
              transition: transform 0.5s;
              margin-top: 8vh;
              &:hover {
                transform: scale(1.1);
              }
            }
            .desc {
              color: #fff;
              font-size: remPc(18);
              font-weight: bold;
              padding-top: 10px;
              text-align: center;
              white-space: normal;
              text-overflow: ellipsis;
              overflow: hidden;
              letter-spacing: 2px;
            }
          }
        }
      }
    }
    .paging {
      width: remPc(360);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8vh auto;
      opacity: 0;
      .arrow {
        width: remPc(23);
        cursor: pointer;
      }
      .number {
        font-size: remPc(23);
        color: #888;
        letter-spacing: 4px;
        font-weight: bold;
      }
    }
  }

  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.5s;
      }
      &-2 {
        transform: rotate(0);
        transition: transform 0.4s 0.3s;
      }
    }
    .intro {
      transform: rotate(-10deg);
      transition: transform 0.4s 0.2s;
    }
    .lists-wrap {
      margin-top: 32vh;
      .lists {
        .swiper-wrap {
          ul {
            .lists-item {
              transform: translateY(0);
              &:nth-of-type(1) {
                transition: transform 0.9s 0.2s;
              }
              &:nth-of-type(2) {
                transition: transform 0.7s 0.4s;
              }
              &:nth-of-type(3) {
                transition: transform 0.5s 0.6s;
              }
            }
          }
        }
      }
      .paging {
        opacity: 1;
        transition: opacity 1s 0.7s;
      }
    }
  }
}
</style>
