<template>
  <div :class="['tab-two', animate && 'animate']">
    <div class="art">
      <img class="art-1" src="../imgs/taching.png" alt="" />
      <img class="art-2" src="../imgs/leaf-4.png" alt="" />
      <img class="art-3" src="../imgs/envronment.png" alt="" />
    </div>
    <div class="learning">
      <img v-for="(item, index) in envs" :key="index" @click="openPopup(item, index)" :src="item.url" alt="" />
    </div>
    <div class="introduce">
      <div class="name">
        <div class="text">
          <span> 上海实体班</span>
        </div>
      </div>
      <div class="detail">
        <p>叶子学堂上海校区坐落于上海市杨浦区长阳创谷头部地段。</p>
        <p>作为叶子学堂的核心校区，这里经常有网易、完美等国内一线公司来交流考察</p>
        <p>教学体系完备，课程内容来自于在运营项目的经验总结。</p>
      </div>
    </div>
    <transition name="bounce">
      <Popup :visible.sync="visible" v-if="visible"> </Popup>
    </transition>
  </div>
</template>

<script>
import Popup from "./PopupTab2.vue";
export default {
  components: { Popup },
  data() {
    return {
      animate: false,
      visible: false,
      envs: [
        { url: require("../imgs/env-1.png") },
        { url: require("../imgs/env-2.png") },
        { url: require("../imgs/env-3.png") },
        { url: require("../imgs/env-4.png") },
      ],
    };
  },

  methods: {
    openPopup(item, index) {
      this.visible = true;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>
<style lang="scss" scoped>
$color: #8a53ec;
.tab-two {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  .art {
    cursor: pointer;
    &-1 {
      position: absolute;
      width: remPc(375);
      left: remPc(56);
      bottom: remPc(200);
      z-index: 11;
      transform: translateX(-150%);
    }
    &-2 {
      position: absolute;
      width: remPc(491);
      left: remPc(240);
      bottom: remPc(90);
      z-index: 12;
      transform: rotate(40deg);
      transform-origin: -150% bottom;
    }
    &-3 {
      width: remPc(683);
      position: absolute;
      left: remPc(90);
      bottom: remPc(120);
      z-index: 13;
      transform: rotate(40deg);
      transform-origin: -150% 200%;
    }
  }
  .learning {
    img {
      position: absolute;
      cursor: pointer;

      &:nth-of-type(2) {
        height: 76vh;
        left: remPc(-120);
        top: 19vh;
        z-index: 1;
        transform: rotate(-90deg);
        transform-origin: right 120%;
      }
      &:nth-of-type(1) {
        height: 74vh;
        top: 4vh;
        left: remPc(76);
        z-index: 2;
        transform: rotate(-90deg);
        transform-origin: right 200%;
      }
      &:nth-of-type(3) {
        height: 72vh;
        left: remPc(617);
        top: 24vh;
        z-index: 3;
        transform: rotate(-90deg);
        transform-origin: right 200%;
      }
      &:nth-of-type(4) {
        height: 99vh;
        left: remPc(263);
        top: 1vh;
        z-index: 4;
        transform: scale(1.2);
        opacity: 0;
        // transform: rotate(-90deg);
        // transform-origin: 18% 150%;
      }
      &.active {
        z-index: 10;
      }
    }
  }
  .introduce {
    position: absolute;
    right: remPc(0);
    bottom: 10vh;
    .name {
      width: remPc(377);
      height: remPc(150);
      background: url("../imgs/zidi.png") center/100% no-repeat;
      font-size: remPc(45);
      font-weight: bold;
      letter-spacing: 4px;
      color: #fff;
      opacity: 0;
      .text {
        transform: rotate(-20deg) translate(remPc(10), remPc(-12));
        overflow: hidden;
        height: remPc(80);
        position: relative;
        span {
          position: absolute;
          display: inline-block;
          bottom: 0;
          transform: rotate(10deg);
          // transform: rotate(-10deg) translate(remPc(10), remPc(20));
        }
      }
    }
    .detail {
      p {
        font-size: remPc(20);
        line-height: remPc(35);
        color: #666;
        letter-spacing: 2px;
        font-weight: bold;
        margin-bottom: remPc(30);
        width: remPc(600);
        &:nth-of-type(1) {
          transform: translateX(200%);
        }
        &:nth-of-type(2) {
          transform: rotate(2deg) translate(200%);
        }
        &:nth-of-type(3) {
          transform: rotate(4deg) translate(200%);
        }
      }
    }
  }
  .code {
    position: fixed;
    width: remPc(96);
    height: remPc(120);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    right: remPc(40);
    bottom: remPc(40);
    img {
      width: remPc(96);
    }
    p {
      color: #666;
      font-weight: bold;
      font-size: remPc(16);
      letter-spacing: 4px;
    }
  }
  &.animate {
    .art {
      &-1 {
        transform: translateX(0);
        transition: transform 0.5s 0.7s;
      }
      &-2 {
        transform: rotate(0);
        transition: transform 0.3s 0.7s;
      }
      &-3 {
        transform: rotate(0);
        transition: transform 0.5s 0.8s;
      }
    }
    .learning {
      transition: transform 0.7s;
      &:hover {
        transform: scale(1.02);
      }
      img {
        &:nth-of-type(2) {
          transform: rotate(0);
          transition: transform 0.5s 0.2s;
        }
        &:nth-of-type(1) {
          transform: rotate(0);
          transition: transform 0.5s 0.1s;
        }
        &:nth-of-type(3) {
          transform: rotate(0);
          transition: transform 0.5s;
        }
        &:nth-of-type(4) {
          transform: scale(1);
          opacity: 1;
          transition: transform 0.5s 0.6s, opacity 0.5s 0.6s;
        }
      }
    }
    .introduce {
      .name {
        cursor: pointer;
        opacity: 1;
        transition: opacity 1s 1s, transform 0.5s;
        &:hover {
          transform: scale(1.1);
        }
      }
      .detail {
        cursor: pointer;
        transition: transform 0.5s;
        &:hover {
          transform: rotate(-20deg);
        }
        p {
          &:nth-of-type(1) {
            transform: translateX(0);
            transition: transform 0.6s 1.1s;
          }
          &:nth-of-type(2) {
            transform: rotate(2deg) translate(remPc(-40));
            transition: transform 0.6s 1.3s;
          }
          &:nth-of-type(3) {
            transform: rotate(4deg) translate(remPc(-100));
            transition: transform 0.6s 1.5s;
          }
        }
      }
    }
  }
}
</style>
