<template>
  <div :class="['tab-five', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-7.png" alt="" />
      <img class="background-2" src="../imgs/leaf-8.png" alt="" />
    </section>
    <section class="time-axis">
      <div class="line">
        <div class="circular">
          <section class="time-intro">
            <div class="year">{{ axisInfos[active]["year"] }}</div>
            <div class="desc">{{ axisInfos[active]["desc"] }}</div>
            <img class="pic" :src="axisInfos[active].url" alt="" />
          </section>
        </div>
        <ul :class="['times']">
          <div :class="['year', 'year-' + active]">
            <li :class="[active == index && 'active']" v-for="(item, index) in axisInfos" :key="index" @click="change(index)">
              {{ item.year }}
            </li>
          </div>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      axisInfos: [
        {
          year: "2019年07月",
          desc: "叶子学堂承办了腾讯课堂上海线下沙龙，并最终取得了圆满成功。",
          url: require("../imgs/ev-01.png"),
        },
        {
          year: "2019年08月",
          desc: "由叶子学堂牵头开展的第一次校企合作顺利结课。学员就业率100%，并收获了业内外一致好评。",
          url: require("../imgs/ev-02.png"),
        },
        {
          year: "2019年12月",
          desc: "叶子学堂获得腾讯课堂“金课堂”奖。线上课程3.0版更新上线，在线课程完课率达到80%。",
          url: require("../imgs/ev-03.png"),
        },
        {
          year: "2020年08月",
          desc: "叶子学堂5周年线下活动开启。活动中分享了新木团队的项目经验。叶子学堂还正式开启了产学结合的探索，并取得了关键阶段的成功。",
          url: require("../imgs/ev-04.png"),
        },
        {
          year: "2020年10月",
          desc: "经过数月准备，叶子学堂旗下“动特效教研团队”正式上线。",
          url: require("../imgs/ev-05.png"),
        },
        {
          year: "2021年10月",
          desc: "叶子学堂全面开启“校企合作新模式”，旗下学员的互联网大厂入职率达到95%，业内外口碑再次提升。",
          url: require("../imgs/ev-06.png"),
        },
      ],
      active: 5,
      animate: false,
    };
  },
  methods: {
    change(index) {
      this.active = index;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-five {
  height: 100vh;
  .background {
    &-1 {
      position: fixed;
      top: -13vh;
      left: remPc(-55);
      height: 60vh;
      transform: rotate(-90deg);
      transform-origin: -50% 100%;
      z-index: 1;
    }
    &-2 {
      height: 40vh;
      position: fixed;
      top: -6vh;
      left: remPc(130);
      transform: rotate(-90deg);
      transform-origin: -50% 100%;
      z-index: 20;
    }
  }
  .time-axis {
    .line {
      position: fixed;
      left: remPc(575);
      transform: rotate(-8deg);
      bottom: 0;
      width: 3px;
      height: 120vh;
      background: transparent;
      z-index: 5;
      &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 0;
        background: #57bd16;
        left: 0;
        bottom: 0;
      }
      .circular {
        position: absolute;
        left: 50%;
        bottom: remPc(480);
        transform: translateX(-50%);
        width: remPc(35);
        height: remPc(35);
        background: $color;
        border-radius: 50%;
        z-index: 11;
        opacity: 0;
        .time-intro {
          position: absolute;
          left: remPc(100);
          bottom: remPc(0);
          transform: translateY(73%) rotate(-16deg);
          width: remPc(554);
          height: remPc(515);
          .year {
            color: $color;
            font-size: remPc(40);
            transform: rotate(-90deg);
            transform-origin: 200% center;
            font-weight: bold;
            opacity: 1;
            margin-top: remPc(100);
          }
          .desc {
            margin-top: remPc(260);
            margin-left: remPc(-60);
            font-size: remPc(18);
            letter-spacing: 2px;
            line-height: remPc(36);
            width: remPc(700);
            font-weight: 600;
            transform: rotate(-90deg);
            transform-origin: 200% center;
            color: #666;
            opacity: 1;
          }
          .pic {
            width: remPc(391);
            margin: 0 0 0 remPc(-150);
            opacity: 0;
            transform: rotate(11deg) translate(200%, remPc(-280));
          }
        }
      }

      .times {
        position: absolute;
        left: remPc(-190);
        width: remPc(140);
        bottom: remPc(120);
        height: remPc(600);
        cursor: pointer;
        padding: 30px;
        transition: transform 1s;
        mask: -webkit-gradient(linear, left center, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
        .year {
          &.year-0 {
            transform: rotate(0deg) translateY(remPc(250));
          }
          &.year-1 {
            transform: rotate(0deg) translateY(remPc(200));
          }
          &.year-2 {
            transform: rotate(0deg) translateY(remPc(150));
          }
          &.year-3 {
            transform: rotate(0deg) translateY(remPc(100));
          }
          &.year-4 {
            transform: rotate(0deg) translateY(remPc(50));
          }
          &.year-5 {
            transform: rotate(0deg) translateY(remPc(0));
          }
          li {
            font-size: remPc(19);
            color: #888;
            font-weight: 600;
            line-height: remPc(50);
            text-align: right;
            opacity: 0;
            transform: translate(remPc(10), remPc(20)) rotate(-20deg);
            &.active {
              visibility: hidden;
            }
            &:hover {
              color: #00d6e3;
            }
          }
        }
      }
    }
  }

  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.5s 0s;
      }
      &-2 {
        transform: rotate(0);
        transition: transform 0.7s 0.2s;
      }
    }
    .time-axis {
      .line {
        &::before {
          height: 120vh;
          transition: height 1s 0.5s;
        }
        .circular {
          opacity: 1;
          transition: opacity 1s 0.9s;
          .time-intro {
            .year {
              transform: rotate(5deg);
              transition: transform 0.7s 0.9s;
            }
            .desc {
              transform: rotate(14deg);
              transition: transform 0.7s 1s;
            }
            .pic {
              opacity: 1;
              transform: rotate(24deg) translate(remPc(-30), remPc(-280));
              transition: opacity 1s 1.6s, transform 1s 1s;
            }
          }
        }

        .times {
          .year {
            transition: transform 0.5s;
            li {
              transition: transform 1s, opacity 1s;
              opacity: 1;
              transform: translate(remPc(10), remPc(0)) rotate(-20deg);
              &:nth-of-type(1) {
                transition-delay: 0.8s;
              }
              &:nth-of-type(2) {
                transition-delay: 0.9s;
              }
              &:nth-of-type(3) {
                transition-delay: 1s;
              }
              &:nth-of-type(4) {
                transition-delay: 1.1s;
              }
              &:nth-of-type(5) {
                transition-delay: 1.2s;
              }
              &:nth-of-type(6) {
                transition-delay: 1.3s;
              }
              &:nth-of-type(7) {
                transition-delay: 1.4s;
              }
            }
          }
        }
      }
    }
  }
}
</style>
