<template>
  <div class="popup" v-if="visible">
    <div class="popup-l">
      <section class="swiper-wrap">
        <swiper ref="popupSwiper" :options="popupOptions">
          <swiper-slide class="slide" v-for="(item, index) in popupInfo.files" :key="index">
            <img :src="item.url" alt="" />
          </swiper-slide>
        </swiper>
      </section>
      <div class="paging">
        <img class="arrow" @click="slidePrev('popup')" src="../imgs/r-3.png" alt="" />
        <span class="number">{{ activeIndex }}</span>
        <img class="arrow" @click="slideNext('popup')" src="../imgs/l-3.png" alt="" />
      </div>
    </div>
    <div class="popup-r">
      <h2>{{ popupInfo.title }}</h2>
      <div class="desc">
        {{ popupInfo.desc }}
      </div>
    </div>
    <div class="close" @click="closePopup">
      <img src="../imgs/close.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["visible", "popupInfo"],
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      popupOptions: {
        allowTouchMove: false,
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex + 1;
          },
        },
      },
    };
  },
  computed: {
    popupSwiper() {
      return this.$refs.popupSwiper.$swiper;
    },
  },
  methods: {
    closePopup() {
      this.$emit("update:visible", false);
      this.$emit("onClose");
    },
    slidePrev(type) {
      this.popupSwiper.slidePrev();
    },
    slideNext(type) {
      this.popupSwiper.slideNext();
    },
  },
  mounted() {
    this.$parent.$parent.handleCloseMousewheel();
  },
  beforeDestroy() {
    this.$parent.$parent.handleOpenMousewheel();
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;

.popup {
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.9);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-l {
    height: 50vh;
    width: 40vw;
    padding-left: 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .swiper-wrap {
      width: remPc(687);
      text-align: center;
      img {
        height: 38vh;
      }
    }
    .paging {
      width: remPc(370);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        width: remPc(20);
        height: remPc(40);
        cursor: pointer;
      }
      .number {
        font-size: remPc(20);
        font-weight: bold;
        color: $color;

        letter-spacing: 2px;
      }
    }
  }
  &-r {
    height: 60vh;
    width: 55vw;
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 6vw 0;
    h2 {
      font-size: remPc(50);
      font-weight: 800;
      margin-bottom: 5vh;
    }

    .desc {
      font-size: remPc(20);
      line-height: remPc(50);
      width: remPc(700);
    }
  }
  .close {
    position: absolute;
    right: remPc(228);
    top: remPc(107);
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: transform 1s;
    }
    img {
      width: remPc(44);
    }
  }
}
</style>
