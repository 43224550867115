<template>
  <div class="service">
    <!-- <div class="qq">
      <p class="name"><img src="../imgs/qq.png" alt="" /><span>QQ</span></p>
      <div class="time">
        <p @click="skipQQ">点击在线咨询</p>
        <p>时间:9:00-22:00</p>
      </div>
    </div> -->
    <div class="wx">
      <p class="name">微信</p>
      <p class="time">时间:9:00-22:00</p>
      <img class="code" src="../imgs/wechat.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    skipQQ() {
      window.open("http://wpa.qq.com/msgrd?v=3&uin=3007210963&site=qq&menu=yes&from=message&isappinstalled=0", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  position: fixed;
  z-index: 999999;
  bottom: 1vh;
  right: 2vh;
  width: remPc(200);
  height: remPc(200);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;
  // border: 1px solid #000;
  // background: rgba(0, 0, 0, 0.756);
  .qq {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    width: remPc(150);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    cursor: pointer;
    .name {
      color: #ffffffd7;
      font-size: remPc(20);
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        width: remPc(30);
      }
    }
    .time {
      height: 50%;
      p {
        text-align: center;
        &:nth-of-type(1) {
          color: #ffffffd7;
          font-size: remPc(18);
        }
        &:nth-of-type(2) {
          color: #ffffff96;
          font-size: remPc(14);
          margin-top: 3px;
        }
      }
    }
  }
  .wx {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: remPc(2) remPc(16) remPc(6) 0;
    box-sizing: border-box;
    .name {
      color: #ffffffd7;
      font-size: remPc(16);
    }

    .code {
      height: 60%;
    }
    .time {
      color: #ffffffd7;
      font-size: remPc(12);
    }
  }
  // .code {
  //   height: 100%;
  //   box-sizing: border-box;
  // }
}
</style>
