<template>
  <div class="leaf">
    <Tabs class="tabs" :active.sync="active"></Tabs>
    <Tab1 v-if="active == 0"></Tab1>
    <Tab2 v-if="active == 1"></Tab2>
    <Tab3 v-if="active == 2" :videos="interviewList"></Tab3>
    <Tab4 v-if="active == 3" :lists="schoolList"></Tab4>
    <Tab5 v-if="active == 4"></Tab5>
    <Tab6 v-if="active == 5"></Tab6>
    <Service></Service>
  </div>
</template>

<script>
import Tabs from "./components/Tabs.vue";
import Tab1 from "./components/Tab-1.vue";
import Tab2 from "./components/Tab-2.vue";
import Tab3 from "./components/Tab-3.vue";
import Tab4 from "./components/Tab-4.vue";
import Tab5 from "./components/Tab-5.vue";
import Tab6 from "./components/Tab-6.vue";
import Service from "./components/Servive.vue";
import { getInfo, getInterview } from "@/api";

export default {
  components: {
    Tabs,
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
    Service,
  },
  data() {
    return {
      active: 0,
      listQuery: {
        pageSize: 9999,
        pageNum: 1,
        company: "YEZI",
        busiType: "XQHZ",
      },
      schoolList: [],
      interviewList: [],
      timer: null,
    };
  },
  methods: {
    // 校企合作
    getInfo() {
      getInfo(this.listQuery).then((result) => {
        const { content } = result;
        this.schoolList = content;
      });
    },
    // 采访资料
    getInterview() {
      getInterview({ company: "YEZI" }).then((result) => {
        this.interviewList = result;
      });
    },
    handleScroll(e) {
      const _this = this;
      function fn() {
        let active = _this.active;
        let direction = e.deltaY > 0 ? "down" : "up";
        if (direction == "down") {
          active < 5 && active++;
        } else {
          active > 0 && active--;
        }
        _this.active = active;
      }
      if (!this.timer) {
        fn();
        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.timer = null;
        }, 1000);
      }
    },
    handleOpenMousewheel() {
      window.addEventListener("mousewheel", this.handleScroll);
    },
    handleCloseMousewheel() {
      window.removeEventListener("mousewheel", this.handleScroll);
    },
  },
  created() {
    this.getInfo();
    this.getInterview();
  },
  beforeDestroy() {
    this.handleCloseMousewheel();
  },
  mounted() {
    this.handleOpenMousewheel();
  },
};
</script>

<style lang="scss" scoped>
.leaf {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url("./imgs/bg@2x.png") center/100% 100% no-repeat;
  .tabs {
    position: fixed;
    right: 0;
    top: 0;
    top: -20px;
    z-index: 88;
  }
}
</style>
