<template>
  <div :class="['tab-one', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-1.png" alt="" />
      <img class="background-2" src="../imgs/leaf-2.png" alt="" />
    </section>
    <section class="swiper-wrap">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="slide" v-for="(item, index) in photos" :key="index">
          <img class="photo" :src="item.url" alt="" />
        </swiper-slide>
      </swiper>
      <div class="swiper-arrow">
        <img class="item" @click="slidePrev" src="../imgs/l-1.png" alt="" />
        <img class="item" @click="slideNext" src="../imgs/r-1.png" alt="" />
      </div>
    </section>
    <section class="swiper-small">
      <div
        :class="['photo-wrap']"
        :style="{
          transform: `translateY(${-10 * (activeIndex - 1)}vh)`,
        }"
      >
        <img
          :class="['photo', activeIndex - 1 == index && 'active']"
          :src="item.smUrl"
          alt=""
          v-for="(item, index) in photosAdd"
          @click="slideTo(index)"
          :key="index"
        />
      </div>
    </section>
    <section class="swiper-intro">
      <div class="name-wrap">
        <div class="name">
          <span>{{ photos[activeIndex - 1]["name"] }}</span>
        </div>
        <div class="work">{{ photos[activeIndex - 1]["work"] }}</div>
        <img class="autograph" src="../imgs/autograph.png" alt="" />
        <img class="leader" src="../imgs/teacher.png" alt="" />
      </div>
      <div class="desc">{{ photos[activeIndex - 1]["desc"] }}</div>
    </section>
    <div class="lines">
      <div class="lines-1 item"></div>
      <div class="lines-2 item"></div>
      <div class="lines-3 item"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      photos: [
        {
          url: require("../imgs/叶子教师-DJ.png"),
          smUrl: require("../imgs/叶子教师头像-DJ.png"),
          name: "DJ",
          work: "高级GUI讲师",
          desc: "GUI教研负责人，资深GUI讲师，13年开发经验。曾供职于第九城市、新浪微博。主导研发《武动乾坤》、《热血无双》、《全民足球》等项目。教学风格稳、准、狠。玩弄多种美术风格于股掌之中。对学生要求严格，口碑优异。",
        },
        {
          url: require("../imgs/叶子教师-花花.png"),
          smUrl: require("../imgs/叶子教师头像-花花.png"),
          name: "花花",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，9年游戏项目经验，深度参与过网易一线项目，如《率土之滨》、《天下》、《蛋蛋派对》等。尤为擅长乙女和二次元风格。阳光、善良、正能量的小姐姐，是广受同学爱戴的教师。",
        },
        {
          url: require("../imgs/叶子教师-小天.png"),
          smUrl: require("../imgs/叶子教师头像-小天.png"),
          name: "小天",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，9年游戏项目经验，曾供职于EA。深度参与过《王牌竞速》、《武林闲侠》、《原神》等项目。教学风格洒脱自然，有着卓越的集体意识和组织能力，被学员亲切的称为阳光教师。",
        },
        {
          url: require("../imgs/叶子教师-业荣.png"),
          smUrl: require("../imgs/叶子教师头像-业荣.png"),
          name: "业荣",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，超10年的游戏研发经验，拥有多平台研发背景。主导及参与过多款大型端游、手游项目。教学风格细致耐心，善于循序渐进的帮助学员成长。被学员称为最可靠的教师。",
        },

        {
          url: require("../imgs/叶子教师-皮皮.png"),
          smUrl: require("../imgs/叶子教师头像-皮皮.png"),
          name: "皮皮",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，8年从业经验，2021年参与并获得了GGAC年度大赛GUI品类银奖。为人耐心和善，性格热情，励志成为UI界私人教练。被学员亲切的称为最积极有活力的教师。",
        },
        {
          url: require("../imgs/叶子教师-骨头.png"),
          smUrl: require("../imgs/叶子教师头像-骨头.png"),
          name: "骨头",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，曾就职腾讯魔方工作室。参与过腾讯《QQ宠物》，《洛克王国》，《火影忍者OL》等项目。擅长休闲游戏和二次元风格。元气与呆萌并存，因细致、认真的教学方式被广大同学们喜爱。",
        },
        {
          url: require("../imgs/叶子教师-良木.png"),
          smUrl: require("../imgs/叶子教师头像-良木.png"),
          name: "良木",
          work: "高级动效讲师",
          desc: "从业10年的东北汉子，资深GUI动效教师，资深动特效设计师，曾供职于字节跳动、心动，《英雄联盟》、《拳皇》、《萃星物语》等项目。励志培养出更多复合型设计人才，讲课风趣幽默，激情满满。",
        },
        {
          url: require("../imgs/叶子教师-黑黑.png"),
          smUrl: require("../imgs/叶子教师头像-黑黑.png"),
          name: "黑黑",
          work: "高级动效讲师",
          desc: "GUI动效教师，4年老搬砖。曾参与《黑潮之上》、《率土之滨》、《非人学园》、《王牌竞速》等项目。讲课风格清晰明快，直击要点，十分受年轻学员的欢迎。",
        },
      ],
      swiperOptions: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 6000,
        },
        loop: true,
        allowTouchMove: false,
        speed: 100,
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.realIndex + 1;
          },
        },
      },
      animate: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    photosAdd() {
      return [this.photos[this.photos.length - 1], ...this.photos, this.photos[0], this.photos[0]];
    },
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #57bd16;
.tab-one {
  height: 100%;
  overflow: hidden;
  position: relative;
  .background {
    &-1 {
      position: fixed;
      bottom: remPc(-40);
      left: remPc(-54);
      width: remPc(855);
      transform: rotate(-90deg);
      transform-origin: -20% 80%;
      z-index: 2;
    }
    &-2 {
      width: remPc(1648);
      position: fixed;
      bottom: remPc(-520);
      left: remPc(120);
      transform: translateY(100%);
      z-index: 2;
    }
  }
  .swiper-wrap {
    width: 80vh;
    height: 90vh;
    bottom: 0;
    position: absolute;
    z-index: 3;
    left: remPc(314);
    opacity: 0;
    .slide {
      .photo {
        height: 100%;
        width: 62vh;
        display: block;
        margin: 0 auto;
      }
    }
    .swiper-arrow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 10;
      top: 30vh;
      opacity: 0;
      .item {
        cursor: pointer;
        width: remPc(23);
      }
    }
  }
  .swiper-small {
    position: fixed;
    left: remPc(50);
    top: 50vh;
    opacity: 1;
    width: remPc(80);
    z-index: 99;
    transform: translateY(-300%);
    height: 30vh;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      box-shadow: 0px 1.5vh 2.5vh 2.5vh #111;
      position: absolute;
      top: 0px;
      z-index: 10;
    }
    &::after {
      content: "";
      width: 100%;

      box-shadow: 0px -1.5vh 2.5vh 2.5vh #111;
      position: absolute;
      bottom: 0px;
      z-index: 10;
    }
    .photo-wrap {
      transition: transform 0.5s;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .photo {
        // width: 100%;
        height: 7vh;
        margin: 1.5vh 0;
        cursor: pointer;
      }
    }
  }
  .swiper-intro {
    position: fixed;
    bottom: remPc(256);
    left: remPc(1250);
    width: remPc(600);
    height: remPc(400);
    .name-wrap {
      width: remPc(241);
      height: remPc(274);
      background: url("../imgs/line.png") center/100% no-repeat;
      opacity: 0;
      transform: scale(1.7);
      position: relative;
      .name {
        transform: rotate(-5deg);
        position: absolute;
        left: remPc(-20);
        top: remPc(70);
        span {
          font-size: remPc(66);
          font-weight: bold;
          color: #fff;
          display: inline-block;
          transform: rotate(-10deg);
          letter-spacing: 6px;
        }
      }
      .work {
        font-size: remPc(17);
        color: #999;
        letter-spacing: 2px;
        position: absolute;
        right: remPc(0);
        top: remPc(140);
        transform: rotate(-30deg);
      }
      .autograph {
        width: remPc(227);
        // transform: translate(remPc(10), remPc(-30));
        position: absolute;
        left: remPc(13);
        top: remPc(60);
      }
      .leader {
        width: remPc(124);
        position: absolute;
        right: remPc(13);
        top: remPc(100);
      }
    }
    .desc {
      position: absolute;
      width: remPc(420);
      font-size: remPc(18);
      color: #666;
      line-height: remPc(36);
      font-weight: 600;
      left: remPc(-50);
      bottom: remPc(30);
      height: 8vh;
      opacity: 0;
      transform: translateY(50px);
    }
  }
  .lines {
    &-1 {
      bottom: -5px;
      transform: rotate(-3deg);
      right: remPc(120);
      height: remPc(180);
    }
    &-2 {
      bottom: -5px;
      transform: rotate(4deg);
      right: remPc(30);
      height: remPc(450);
    }

    &-3 {
      bottom: 50px;
      transform: rotate(-5deg);
      left: remPc(50);
      height: remPc(500);
    }
    .item {
      position: absolute;
      width: 3px;
      z-index: 0;

      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        background: $color;
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0;
      }
    }
  }
  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.5s 0.2s;
      }
      &-2 {
        transform: translateY(0);
        transition: transform 0.5s;
      }
    }
    .swiper-wrap {
      opacity: 1;
      transition: opacity 0.6s 1s;
      .swiper-arrow {
        opacity: 1;
        transition: opacity 1s 1.1s;
      }
    }
    .swiper-small {
      transform: translateY(-100%);
      transition: transform 1s 0.4s;
    }
    .swiper-intro {
      .name-wrap {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.1s 1.7s, transform 0.2s 1.7s;
      }
      .desc {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 1s 1.2s, transform 1s 1.2s;
      }
    }
    .lines {
      .item {
        &::after {
          transition: height 1s 1.2s;
          height: 100%;
        }
      }
    }
  }
}
</style>
