<template>
  <div :class="['tab-six', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-9.png" alt="" />
      <img class="background-2" src="../imgs/leaf-10.png" alt="" />
    </section>
    <section class="room">
      <h2>叶子学堂</h2>
      <img src="../imgs/room.jpg" alt="" />
    </section>
    <div class="info">
      <p>校区地址：上海市杨浦区大桥街道长阳路1687号长阳创谷1号楼1单元410室</p>
      <p>联系信箱：JIANGWEI@leafstudio.cn</p>
      <p><a style="color: #fff" target="_blank" href="https://yezi.ke.qq.com">腾讯课堂：https://yezi.ke.qq.com</a></p>
      <p class="wechat"><span>微信联系：</span><img src="../imgs/wechat.png" alt="" /></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animate: false,
      codes: [
        // { label: "叶子公众号", url: require("../../../mb/leaf/imgs/code-1.png") },
        // { label: "娜娜老师", url: require("../../../mb/leaf/imgs/code-2.png") },
        // { label: "rose老师", url: require("../../../mb/leaf/imgs/code-3.png") },
        // { label: "贝贝老师", url: require("../../../mb/leaf/imgs/code-4.png") },
        { label: "", url: require("../imgs/wechat.png") },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
.tab-six {
  height: 100vh;
  position: relative;
  overflow: hidden;
  .background {
    &-1 {
      position: absolute;
      height: 37vh;
      left: remPc(456);
      top: -5vh;
      transform: translateY(-100%);
    }
    &-2 {
      position: absolute;
      height: 38vh;
      left: remPc(456+60);
      top: 3vh;
      transform: rotate(90deg);
      transform-origin: 200% 150%;
    }
  }
  .room {
    height: 41vh;
    width: 60vh;
    display: inline-block;
    position: absolute;
    left: remPc(286);
    top: 27vh;
    transform: rotate(-90deg);
    transform-origin: -100% 90%;
    h2 {
      color: #57bd16;
      font-size: remPc(55);
      position: absolute;
      z-index: 2;
      left: 10px;
      opacity: 0;
    }
    img {
      height: 32vh;
      position: absolute;
      bottom: rem(20);
      z-index: 3;
      transform: rotate(-10deg);
    }
  }
  .info {
    bottom: 16vh;
    position: absolute;
    right: remPc(260);
    p {
      color: rgba(255, 255, 255, 0.842);
      font-size: remPc(20);
      margin-bottom: remPc(5);
      transform: translateY(30px);
      opacity: 0;
    }
    .wechat {
      span {
        float: left;
      }
      img {
        width: remPc(80);
        float: left;
      }
    }
  }
  &.animate {
    .background {
      &-1 {
        transform: translateY(0);
        transition: transform 0.5s;
      }
      &-2 {
        transform: rotate(0);
        transition: transform 0.7s 0.2s;
      }
    }
    .room {
      transform: rotate(0);
      transition: transform 0.5s 0.5s;
      h2 {
        opacity: 1;
        transition: opacity 2s 1s;
      }
    }
    .info {
      p {
        transform: translateY(0px);
        transition: transform 0.3s, opacity 1s;
        opacity: 1;
        &:nth-of-type(1) {
          transition-delay: 0.4s;
        }
        &:nth-of-type(2) {
          transition-delay: 0.7s;
        }
        &:nth-of-type(3) {
          transition-delay: 1s;
        }
        &:nth-of-type(4) {
          transition-delay: 1.3s;
        }
      }
      .codes {
        transform: translateY(0px);
        transition: transform 0.3s, opacity 1s;
        transition-delay: 1.3s;
        opacity: 1;
      }
    }
  }
}
</style>
