<template>
  <div class="popup" v-if="visible">
    <slot></slot>
    <div class="close" @click="closePopup">
      <img src="../imgs/close.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["visible"],
  methods: {
    closePopup() {
      this.$emit("update:visible", false);
    },
  },
  mounted() {
    this.$parent.$parent.handleCloseMousewheel();
  },
  beforeDestroy() {
    this.$parent.$parent.handleOpenMousewheel();
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.popup {
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.9);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-l {
    height: 60vh;
    width: 45vw;
    padding-left: 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .swiper-wrap {
      width: remPc(687);
      img {
        height: 53vh;
      }
    }
    .paging {
      width: remPc(370);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        width: remPc(20);
        height: remPc(40);
        cursor: pointer;
      }
      .number {
        font-size: remPc(20);
        font-weight: bold;
        color: $color;

        letter-spacing: 2px;
      }
    }
  }
  &-r {
    height: 60vh;
    width: 55vw;
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 6vw 3vw;
    h2 {
      font-size: remPc(50);
      font-weight: 800;
    }
    .zx {
      margin: remPc(20) 0 remPc(40) 0;
      transform: translateX(-20px);
      img {
        width: remPc(800);
      }
    }
    .desc {
      font-size: remPc(20);
      line-height: remPc(50);
      width: remPc(700);
    }
  }
  .close {
    position: absolute;
    right: remPc(228);
    top: remPc(107);
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: transform 1s;
    }
    img {
      width: remPc(44);
    }
  }
}
</style>
